import { AppBar as MuiAppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: theme.palette.background.default,
  backdropFilter: 'blur(1px)',
  transition: theme.transitions.create(['background', 'backdrop-filter'], {
    duration: theme.transitions.duration.standard,
  }),
}));

export default AppBar;



