'use client';
import React, { useState, useEffect } from 'react';
import { Toolbar, Container, useMediaQuery, Box } from '@mui/material';
import Link from 'next/link';
import { useTheme } from '@/hooks/useTheme';
import AppBar from './Appbar';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import ActionIcons from './ActionIcons';
import LogoComponent from '../layouts/LogoComponent';
import { ConfigService } from '@/config';

const FADE_DURATION = 300;
const STAGGER_DELAY = 50;

const configService = ConfigService.getInstance();
const NAV_ITEMS = configService.getNavItems();

const Header: React.FC = () => {
  const { mode, toggleTheme, theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  if (!isMounted) return null;

  return (
    <AppBar position="fixed" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            padding: theme.spacing(0, 2),
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginRight: theme.spacing(2),
            }}
          >
            <Link href="/" passHref>
              <LogoComponent />
            </Link>
          </Box>
          {!isMobile && (
            <DesktopNav
              navItems={NAV_ITEMS}
              customTheme={theme}
              fadeDuration={FADE_DURATION}
              staggerDelay={STAGGER_DELAY}
            />
          )}
          <ActionIcons
            isMobile={isMobile}
            mode={mode}
            toggleTheme={toggleTheme}
            toggleDrawer={toggleDrawer}
            customTheme={theme}
            fadeDuration={FADE_DURATION}
            staggerDelay={STAGGER_DELAY}
            navItemsLength={NAV_ITEMS.length}
          />
          {isMobile && (
            <MobileNav
              drawerOpen={drawerOpen}
              toggleDrawer={toggleDrawer}
              navItems={NAV_ITEMS}
              mode={mode}
              toggleTheme={toggleTheme}
              customTheme={theme}
              fadeDuration={FADE_DURATION}
              staggerDelay={STAGGER_DELAY}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;


