import { BehaviorSubject, Observable } from 'rxjs';

interface AppConfig {
  apiUrl: string;
  defaultTheme: 'light' | 'dark';
  siteTitle: string;
  siteDescription: string;
  siteName: string;
  siteUrl: string;
  twitterHandle: string;
  copywriteText: string;
  homepageTitle: string;
  homepageDescription: string;
  navItems: Array<{ label: string; path: string }>;
  coreWebVitalsMetrics: string[];
  revalidateTime: number;
  googleAnalyticsId?: string;
  facebookPixelId?: string;
  gtmId?: string;
  sendGridApiKey?: string;
  senderEmail?: string;
  sendGridWaitlistListId?: string;
}

function getEnvVar(key: string, fallback: string = ''): string {
  const value = process.env[key];
  return value !== undefined ? value : fallback;
}

const DEFAULT_CONFIG: AppConfig = {
  apiUrl: getEnvVar('NEXT_PUBLIC_API_URL', '/api'),
  defaultTheme: (getEnvVar('NEXT_PUBLIC_DEFAULT_THEME', 'dark') as 'light' | 'dark'),
  siteTitle: getEnvVar('NEXT_PUBLIC_SITE_TITLE', 'Site Name'),
  siteDescription: getEnvVar('NEXT_PUBLIC_SITE_DESCRIPTION', 'Site Description'),
  siteName: getEnvVar('NEXT_PUBLIC_SITE_NAME', 'Site Name'),
  siteUrl: getEnvVar('NEXT_PUBLIC_SITE_URL', 'http://localhost:3000'),
  twitterHandle: getEnvVar('NEXT_PUBLIC_TWITTER_HANDLE', '@handle'),
  copywriteText: `© ${new Date().getFullYear()} ${getEnvVar('NEXT_PUBLIC_SITE_NAME', 'Site Name')}`,
  homepageTitle: getEnvVar('NEXT_PUBLIC_HOMEPAGE_TITLE', 'Site Name'),
  homepageDescription: getEnvVar('NEXT_PUBLIC_HOMEPAGE_DESCRIPTION', 'Site Description'),
  navItems: [
    { label: 'FAQ', path: '#faq' },
    { label: 'Early access', path: '#waitlist' }
  ],
  coreWebVitalsMetrics: ['Largest Contentful Paint', 'First Input Delay', 'Cumulative Layout Shift'],
  revalidateTime: parseInt(getEnvVar('NEXT_PUBLIC_REVALIDATE_TIME', '86400')),
  googleAnalyticsId: process.env.NEXT_PUBLIC_GA_ID,
  facebookPixelId: process.env.NEXT_PUBLIC_FB_PIXEL_ID,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  sendGridApiKey: process.env.SENDGRID_API_KEY,
  senderEmail: process.env.SENDER_EMAIL,
  sendGridWaitlistListId: process.env.SENDGRID_WAITLIST_LIST_ID
};

export class ConfigService {
  private static instance: ConfigService;
  private configSubject: BehaviorSubject<AppConfig>;

  private constructor() {
    this.configSubject = new BehaviorSubject<AppConfig>(DEFAULT_CONFIG);
  }

  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }
    return ConfigService.instance;
  }

  get config$(): Observable<AppConfig> {
    return this.configSubject.asObservable();
  }

  loadConfig(): void {
    if (process.env.NODE_ENV === 'production') return;

    fetch('/config.json')
      .then(res => res.ok ? res.json() : Promise.reject('Failed to load config'))
      .then(loadedConfig => {
        if (loadedConfig) {
          this.updateConfig({ ...this.getCurrentConfig(), ...loadedConfig });
        }
      })
      .catch(() => {
        console.warn('Failed to load config.json, using default configuration');
      });
  }

  updateConfig(updatedConfig: Partial<AppConfig>): void {
    const currentConfig = this.getCurrentConfig();
    const newConfig = { ...currentConfig, ...updatedConfig };
    this.configSubject.next(newConfig);
  }

  getCurrentConfig(): AppConfig {
    return this.configSubject.getValue();
  }

  getConfigProperty<K extends keyof AppConfig>(property: K): AppConfig[K] {
    return this.configSubject.value[property];
  }

  // Backwards compatible methods
  getSendGridWaitlistListId(): string | undefined {
    return this.getConfigProperty('sendGridWaitlistListId');
  }

  getSendGridApiKey(): string | undefined {
    return this.getConfigProperty('sendGridApiKey');
  }

  getSenderEmail(): string | undefined {
    return this.getConfigProperty('senderEmail');
  }

  getNavItems(): Array<{ label: string; path: string }> {
    return this.getConfigProperty('navItems');
  }

  resetToDefault(): void {
    this.configSubject.next(DEFAULT_CONFIG);
  }

  updateConfigProperty<K extends keyof AppConfig>(key: K, value: AppConfig[K]): void {
    this.updateConfig({ [key]: value });
  }

  hasConfigProperty<K extends keyof AppConfig>(key: K): boolean {
    return this.configSubject.value[key] !== undefined;
  }

  reloadConfig(): void {
    this.configSubject.next(DEFAULT_CONFIG);
    this.loadConfig();
  }

  getSiteTitle(): string {
    return this.getConfigProperty('siteTitle');
  }

  getSiteDescription(): string {
    return this.getConfigProperty('siteDescription');
  }

  getSiteName(): string {
    return this.getConfigProperty('siteName');
  }

  getSiteUrl(): string {
    return this.getConfigProperty('siteUrl');
  }

  getTwitterHandle(): string {
    return this.getConfigProperty('twitterHandle');
  }
}

export const configService = ConfigService.getInstance();