import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/CssBaseline/CssBaseline.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-montserrat\",\"display\":\"swap\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/app/src/components/providers/AnalyticsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/app/src/components/util/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/util/TrackingScripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/theme/ThemeProvider.tsx");
