'use client';
import React from 'react';
import Script from 'next/script';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { AnalyticsService } from '@/services/analytics/AnalyticsService';
import { ConfigService } from '@/config';

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const configService = useRef(ConfigService.getInstance());
  const analyticsService = useRef(AnalyticsService.getInstance());
  const { googleAnalyticsId, facebookPixelId } = configService.current.getCurrentConfig();

  useEffect(() => {
    analyticsService.current.init();
  }, []);

  useEffect(() => {
    analyticsService.current.trackEvent({
      name: 'page_view',
      category: 'navigation',
      label: pathname,
    });
  }, [pathname, searchParams]);

  if (process.env.NODE_ENV !== 'production') {
    return <>{children}</>;
  }

  return (
    <>
      {googleAnalyticsId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
            strategy="worker"
          />
          <Script id="google-analytics" strategy="worker">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleAnalyticsId}', {
                page_path: window.location.pathname,
                send_page_view: false
              });
            `}
          </Script>
        </>
      )}
      {facebookPixelId && (
        <Script id="facebook-pixel" strategy="afterInteractive">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${facebookPixelId}');
          `}
        </Script>
      )}
      {children}
    </>
  );
}
