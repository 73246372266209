'use client';
import React from 'react';
import { Typography, Box } from '@mui/material';
import { ConfigService } from '@/config';

import { useTheme } from '@/hooks/useTheme';

const configService = ConfigService.getInstance();

const Footer: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        py: theme.spacing(2),
        textAlign: 'center',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Typography variant="body2" color="textSecondary">
        {configService.getConfigProperty('copywriteText')}
      </Typography>
    </Box>
  );
};

export default Footer;


