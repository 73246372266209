import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '@/config';
import { VitalsService, WebVitalMetric } from './vitals/VitalsService';

export type AnalyticsEvent = {
  name: string;
  value?: number;
  category?: string;
  label?: string;
  nonInteraction?: boolean;
  timestamp: number;
  page?: string;
};

export class AnalyticsService {
  private static instance: AnalyticsService;
  private readonly configService: ConfigService;
  private readonly vitalsService: VitalsService;
  private readonly events$ = new BehaviorSubject<AnalyticsEvent[]>([]);
  private isInitialized = false;

  private constructor() {
    this.configService = ConfigService.getInstance();
    this.vitalsService = VitalsService.getInstance();
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }

  public init(): void {
    if (this.isInitialized) return;
    this.vitalsService.init();
    this.setupVitalsTracking();
    this.isInitialized = true;
  }

  private setupVitalsTracking(): void {
    if (process.env.NODE_ENV === 'production') {
      this.vitalsService.getMetrics$().subscribe((metrics) => {
        Object.entries(metrics).forEach(([name, metric]) => {
          if (metric) this.trackVitalMetric(name, metric);
        });
      });
    }
  }

  private trackVitalMetric(name: string, metric: WebVitalMetric): void {
    this.trackEvent({
      name: `web_vital_${name}`,
      category: 'Web Vitals',
      value: metric.value,
      label: metric.rating,
      nonInteraction: true
    });
  }

  public trackEvent(event: Omit<AnalyticsEvent, 'timestamp'>): void {
    if (process.env.NODE_ENV !== 'production') return;

    const fullEvent = {
      ...event,
      timestamp: Date.now(),
      page: typeof window !== 'undefined' ? window.location.pathname : undefined,
    };

    const currentEvents = this.events$.getValue();
    this.events$.next([...currentEvents, fullEvent]);

    const { googleAnalyticsId, facebookPixelId } = this.configService.getCurrentConfig();

    if (googleAnalyticsId && window.gtag) {
      window.gtag('event', event.name, {
        event_category: event.category,
        event_label: event.label,
        value: event.value,
        non_interaction: event.nonInteraction,
      });
    }

    if (facebookPixelId && window.fbq) {
      window.fbq('track', event.name, {
        content_category: event.category,
        content_name: event.label,
        value: event.value,
      });
    }
  }

  public getEvents$() {
    return this.events$.asObservable();
  }

  public getVitals$() {
    return this.vitalsService.getMetrics$();
  }
}


