import { BehaviorSubject } from 'rxjs';
import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

export type WebVitalMetric = {
  id: string;
  name: string;
  value: number;
  rating: 'good' | 'needs-improvement' | 'poor';
  delta: number;
  navigationType: string;
};

export type VitalsMetrics = {
  cls?: WebVitalMetric;
  fcp?: WebVitalMetric;
  inp?: WebVitalMetric;
  lcp?: WebVitalMetric;
  ttfb?: WebVitalMetric;
};

export class VitalsService {
  private static instance: VitalsService;
  private metricsSubject = new BehaviorSubject<VitalsMetrics>({});
  private isInitialized = false;

  private constructor() { }

  public static getInstance(): VitalsService {
    if (!VitalsService.instance) {
      VitalsService.instance = new VitalsService();
    }
    return VitalsService.instance;
  }

  public init(): void {
    if (this.isInitialized || typeof window === 'undefined') return;

    onCLS((metric) => this.handleMetric('cls', metric));
    onFCP((metric) => this.handleMetric('fcp', metric));
    onINP((metric) => this.handleMetric('inp', metric));
    onLCP((metric) => this.handleMetric('lcp', metric));
    onTTFB((metric) => this.handleMetric('ttfb', metric));

    this.isInitialized = true;
  }

  private handleMetric(type: keyof VitalsMetrics, metric: WebVitalMetric): void {
    const currentMetrics = this.metricsSubject.getValue();
    this.metricsSubject.next({
      ...currentMetrics,
      [type]: metric,
    });
  }

  public getMetrics$() {
    return this.metricsSubject.asObservable();
  }

  public getCurrentMetrics(): VitalsMetrics {
    return this.metricsSubject.getValue();
  }
}


