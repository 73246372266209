'use client'
import React from 'react';
import { Box, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import { keyframes } from '@emotion/react';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@/theme/ThemeProvider';

const fadeIn = keyframes`
 from {
   opacity: 0;
   transform: translateY(-10px);
 }
 to {
   opacity: 1;
   transform: translateY(0);
 }
`;

interface ActionIconsProps {
  isMobile: boolean;
  mode: 'light' | 'dark';
  toggleTheme: () => void;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  customTheme: Theme;
  fadeDuration: number;
  staggerDelay: number;
  navItemsLength: number;
}

const ActionIcons: React.FC<ActionIconsProps> = ({
  isMobile,
  mode,
  toggleTheme,
  toggleDrawer,
  customTheme,
  fadeDuration,
  staggerDelay,
  navItemsLength,
}) => {
  const { theme } = useTheme();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          animation: `${fadeIn} ${fadeDuration}ms ${navItemsLength * staggerDelay + staggerDelay
            }ms both`,
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          aria-label={isMobile ? 'menu' : 'toggle theme'}
          onClick={
            isMobile
              ? (event) => toggleDrawer(true)(event)
              : toggleTheme
          }
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          {isMobile ? (
            <MenuIcon sx={{ fontSize: 20, strokeWidth: 1 }} />
          ) : mode === 'dark' ? (
            <Brightness7Icon sx={{ fontSize: 20, strokeWidth: 1 }} />
          ) : (
            <Brightness4Icon sx={{ fontSize: 20, strokeWidth: 1 }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default ActionIcons;


