import React from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Box,
  IconButton,
  Theme,
} from '@mui/material';
import Link from 'next/link';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { NavItem } from '@/types/navigation';

import { useTheme } from '@/hooks/useTheme';

interface MobileNavProps {
  drawerOpen: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  navItems: NavItem[];
  mode: 'light' | 'dark';
  toggleTheme: () => void;
  customTheme: Theme;
  fadeDuration: number;
  staggerDelay: number;
}

const MobileNav: React.FC<MobileNavProps> = ({
  drawerOpen,
  toggleDrawer,
  navItems,
  mode,
  toggleTheme,
  customTheme,
}) => {
  const { theme } = useTheme();
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{
          width: 250,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {navItems.map((item: NavItem, index: number) => (
            <ListItemButton
              key={item.path}
              component={Link}
              href={item.path}
              onClick={toggleDrawer(false)}
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
        <Box
          sx={{
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            gap: theme.spacing(1),
          }}
        >
          <IconButton
            onClick={toggleTheme}
            sx={{
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '50%',
              padding: theme.spacing(1),
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            aria-label="toggle theme"
          >
            {mode === 'dark' ? (
              <Brightness7Icon sx={{ fontSize: 20, strokeWidth: 1 }} />
            ) : (
              <Brightness4Icon sx={{ fontSize: 20, strokeWidth: 1 }} />
            )}
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileNav;


